import React from 'react'
import { SingleFileAttachment } from '@digitalworkflow/dwreactcommon'
import { ProjectDocumentDocumentType } from '@digitalworkflow/dwtranslateclient'

const SingleFileAttachmentPage = () => {
  return (
    <div>
      <SingleFileAttachment
        onChange={(files: Partial<ProjectDocumentDocumentType>[]) =>
          console.log(files)
        }
        ref_id='task50'
        projectName='dwportal'
        hideDocumentUploadSection={false}
        extensions={['.jpg', '.jpeg', '.png', '.xls', '.ppt', '.pptx', '.mp4']}
      />
    </div>
  )
}

export default SingleFileAttachmentPage
