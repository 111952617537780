/* eslint-disable camelcase */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  useRef
} from 'react'
import Role from '../../components/Role/Role'
import SearchInput from '../../components/SearchInput/SearchInput'
import {
  WorkgroupService,
  AuthService,
  WorkgroupType as BaseWorkgroupType
} from '@digitalworkflow/dwloginclient'
import style from './RoleList.module.scss'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { Button, Spinner } from 'reactstrap'
import { sortArrayByEveryPart } from '../../utils/sortArray'
import { SelectDropdown } from '@digitalworkflow/dwreactcommon'
import { roleSelectStyle } from './select.style'
import { exportData } from '../../utils/exportData'
interface IWorkgroupList {
  handleAddWorkgroup?: () => void
  handleActiveWorkgroup: (workgroup: WorkgroupType) => void
  isReadOnlyAccess: boolean
}
interface WorkgroupType extends BaseWorkgroupType {
  total_members?: number
}
const WorkgroupList = forwardRef((props: IWorkgroupList, ref) => {
  const authService = AuthService.instance()

  WorkgroupService.setAuthServiceInstance(authService)
  const workgroupService = new WorkgroupService()

  const [workgroups, setWorkgroups] = useState<WorkgroupType[]>([])
  const [filterWorkgroups, setFilterWorkgroups] = useState<WorkgroupType[]>([])
  const [activeWorkgroupId, setActiveWorkgroupId] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const searchInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    getWorkgroups()
  }, [])
  useImperativeHandle(ref, () => ({
    getWorkgroups
  }))
  const getWorkgroups = useCallback(async () => {
    setIsLoading(true)
    const _workgroups: ApiResultType<WorkgroupType[]> =
      await workgroupService.getAllWorkgroup('restricted')
    setIsLoading(false)
    if (!_workgroups.is_error && _workgroups.data) {
      // Sorting the array by "Major :: Minor :: Section :: Part"
      const _workgroupList = [..._workgroups.data].sort(
        sortArrayByEveryPart('formatted_workgroup_name')
      )
      if (
        _workgroupList.length > 0 &&
        activeWorkgroupId === '' &&
        _workgroupList[0].id !== undefined
      ) {
        setActiveWorkgroupId(_workgroupList[0].id)
        props.handleActiveWorkgroup(_workgroupList[0])
      } else {
        const _workgroup: any = _workgroupList.find(
          (item) => item.id === activeWorkgroupId
        )

        if (_workgroup) {
          _workgroup.formated_approver_group_name = _workgroupList.find(
            (item) => item.id === _workgroup.approver_group_name
          )?.formatted_workgroup_name
        }
        props.handleActiveWorkgroup(_workgroup)
      }
      setWorkgroups([..._workgroupList])
      setFilterWorkgroups([..._workgroupList])
      setSearchBlank()
    }
  }, [
    setWorkgroups,
    setFilterWorkgroups,
    setIsLoading,
    activeWorkgroupId,
    setActiveWorkgroupId
  ])
  const setSearchBlank = useCallback(() => {
    if (searchInputRef.current) {
      searchInputRef.current.value = ''
    }
  }, [searchInputRef])
  const handleActiveRole = useCallback(
    (workgroupRoleId: string | undefined) => {
      if (workgroupRoleId) {
        setActiveWorkgroupId(workgroupRoleId)
        const _workgroup: any = workgroups.find(
          (item) => item.id === workgroupRoleId
        )

        if (_workgroup) {
          _workgroup.formated_approver_group_name = workgroups.find(
            (item) => item.id === _workgroup.approver_group_name
          )?.formatted_workgroup_name
        }
        props.handleActiveWorkgroup(_workgroup)
      }
    },
    [setActiveWorkgroupId, workgroups]
  )

  const handleChange = useCallback(
    (value: string) => {
      let _workgroups = [...workgroups]
      _workgroups = _workgroups.filter((item: any) =>
        item.formatted_workgroup_name
          .toLowerCase()
          .includes(value.toLowerCase())
      )
      setFilterWorkgroups([..._workgroups])
    },
    [workgroups, setFilterWorkgroups]
  )

  const handleChangeDropdown = useCallback((value: any) => {
    if (value.value === 'export') {
      exportData('workgroups/export', 'workgroups')
    }
  }, [])

  return (
    <div className={style.mainContainer}>
      <div className={style.header}>
        <div className='flex-1'>
          <SelectDropdown
            style={roleSelectStyle as any}
            options={[
              {
                label: `Active Workgroups (${workgroups.length})`,
                value: 'activeWorkgroup'
              },
              {
                label: 'Export All Workgroups',
                value: 'export'
              },
              {
                label: `Archived Workgroups `,
                value: null
              }
            ]}
            value={{
              value: 'activeWorkgroup',
              label: `Active Workgroups (${workgroups.length})`
            }}
            onChange={handleChangeDropdown}
          />
        </div>

        <Button
          type='button'
          color='add'
          disabled={props.isReadOnlyAccess}
          className='btn-sm  flex-1'
          onClick={props.handleAddWorkgroup}
        >
          + Add New Workgroup
        </Button>
      </div>
      <SearchInput
        placeholder='Search Workgroup'
        onChange={handleChange}
        ref={searchInputRef}
      />
      <div className={style.roleList}>
        {isLoading && (
          <div className='flex justify-center'>
            <Spinner />
          </div>
        )}
        {filterWorkgroups.length > 0
          ? filterWorkgroups.map((item, index) => (
              <Role
                title={item.formatted_workgroup_name ?? ''}
                key={index}
                workgroupRoleId={item.id}
                activeWorkgroupRoleId={activeWorkgroupId}
                handleActiveRole={handleActiveRole}
                totalMembers={item?.total_members}
              />
            ))
          : !isLoading && (
              <div className='flex justify-center'>
                <span>No data found</span>
              </div>
            )}
      </div>
    </div>
  )
})
export default WorkgroupList
