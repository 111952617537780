import React from 'react'
import SentEmailReportsList from '../../components/EmailReportsList/SentEmailReportsList'

const SentEmailReports = () => {
  return (
    <div className='p-2'>
      <div className='d-flex justify-content-between align-items-center'>
        <h6>Sent Email Reports</h6>
      </div>

      <SentEmailReportsList />
    </div>
  )
}
export default SentEmailReports
