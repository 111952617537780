import { getBlockedEmailAPIURL } from '../constants'

export interface ISentEmailGetOptions {
  page?: number
  limit?: number
  filter?: {
    email?: string
    email_type?: string
    receivedDateTime?: string
    receivedDateTime_type?: string
    subject?: string
    subject_type?: string
  }
  sort?: string
  sortOrder?: string
}

/**
 * Get Sent Emails Data from API
 *
 * @param param0
 * @returns
 */
export const getBlockedEmails = async ({
  page = 1,
  limit = 50,
  filter = {},
  sort,
  sortOrder
}: ISentEmailGetOptions = {}) => {
  const params = new URLSearchParams({
    page: `${page}`,
    limit: `${limit}`,
    ...filter
  })

  if (sort) params.append('sort', sort)
  if (sortOrder) params.append('sortOrder', sortOrder)

  const url = `${getBlockedEmailAPIURL()}logs/block-emails?${params.toString()}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return response.json()
}
