/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react'
import {
  AuthService,
  WorkgroupService,
  UserService,
  UserType,
  // WorkgroupService,
  WorkgroupType
} from '@digitalworkflow/dwloginclient'
import style from './EditRole.module.scss'
import { sortArray } from '../../utils/sortArray'
import OverlayLoader from '../../components/OverlayLoader/overlayLoader'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'

import LoginPortalGrid from '../../components/LoginPortalGrid/LoginPortalGrid'
// import moment from 'moment'

const isNotEmpty = (value: string) => value.trim().length > 0
const isWithinMaxLength = (value: string, maxLength: number) =>
  value.length <= maxLength

interface FormValue {
  work_group_name: string
  user_id: string
  // approver_group_name?: string
}

interface Props {
  activeWorkgroup: WorkgroupType | null
  handleAddUser2Workgroup: (values: any) => void
  id?: string | 'addUser'
}

const authService = AuthService.instance()
UserService.setAuthServiceInstance(authService)
WorkgroupService.setAuthServiceInstance(authService)
const userService = new UserService()
const workgroupService = new WorkgroupService()

interface IOption {
  value: string
  label: string
}

type UserFilter = {
  roles: string[]
  workgroups: string[]
  portals: string[]
  status: boolean | undefined
}

const AddUser = ({ activeWorkgroup, handleAddUser2Workgroup, id }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<UserType[]>([])
  const [filter, setFilter] = useState<UserFilter>({
    roles: [],
    workgroups: [],
    portals: [],
    status: undefined
  })

  useEffect(() => {
    console.log('use effect user filter before get users call: ', filter)

    handleFilter('true', 'status')

    getAllUsers()

    console.log(
      isNotEmpty,
      isWithinMaxLength,
      isLoading,
      activeWorkgroup,
      id,
      workgroupService
    )
  }, [])

  const getAllUsers = useCallback(async () => {
    setIsLoading(true)
    const _result: ApiResultType<UserType[]> = await userService.getAllUser(
      filter
    )

    console.log('get all users: ', _result)

    if (!_result.is_error) {
      const data = _result.data ?? []
      const sortData = sortArray(data, 'first_name')
      console.log('users list: ', sortData)
      setUsers(sortData)
    }
    setIsLoading(false)
  }, [setUsers, setIsLoading, filter])

  const handleFilter = useCallback(
    (value: any, key: keyof UserFilter) => {
      console.log('handle filter --- value, key: ', value, key)
      const _filter = { ...filter }
      if (key !== 'status') {
        if (value.length > 0) {
          _filter[key] = value.map((item: IOption) => item.value)
        } else {
          _filter[key] = []
        }
      } else {
        if (value?.value === 'true') {
          _filter[key] = true
        } else if (value?.value === 'false') {
          _filter[key] = false
        } else {
          delete _filter[key]
        }
      }

      setFilter({ ..._filter })
    },
    [setFilter, filter, users]
  )

  const columnDefs = [
    {
      headerName: 'Name',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      minWidth: 140,
      flex: 2,
      floatingFilter: true,
      valueGetter: (params: any) =>
        params.data.first_name + ' ' + params.data.last_name,
      comparator: (a: any, b: any) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      minWidth: 140,
      flex: 2,
      floatingFilter: true,
      comparator: (a: any, b: any) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
    },
    {
      field: 'Action',
      headerName: 'Action',
      filter: '',
      sortable: false,
      flex: 2,
      resizable: true,
      cellRenderer: (params: any) => (
        <a
          className='action-text'
          onClick={() => {
            console.log('add to group call', params.data)
            addUser2Workgroup(params.data)
          }}
        >
          Add to Workgroup
        </a>
      )
    }
  ]

  const addUser2Workgroup = useCallback(async (values: FormValue) => {
    console.log('add user to workgroup: ', activeWorkgroup, values)
    handleAddUser2Workgroup(values)
  }, [])

  return (
    <div className={`p-2 ${style.formContainer}`}>
      Add Users to Workgroup
      {/* flex: '1 1 90%', overflow: 'auto',  */}
      {isLoading ? (
        <div className='flex justify-center'>
          <OverlayLoader />
        </div>
      ) : (
        <LoginPortalGrid rowData={users} columnDefs={columnDefs} />
      )}
    </div>
  )
}

export default AddUser
