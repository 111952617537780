import React, { useEffect, useState, useCallback } from 'react'
import { Spinner, Button } from 'reactstrap'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import {
  AuthService,
  UserService,
  AccessRequestService
} from '@digitalworkflow/dwloginclient'
import { toast } from 'react-toastify'
import style from './WorkgroupAccessRequestsList.module.scss'
import { useNotif } from '../../context/NotifContext'

const defaulColDef = {
  sortable: true,
  unSortIcon: true,
  filter: 'agTextColumnFilter',
  minWidth: 140,
  floatingFilter: true
}

const authService = AuthService.instance()
UserService.setAuthServiceInstance(authService)

AccessRequestService.setAuthServiceInstance(authService)
const accessRequestService = AccessRequestService.instance()

const WorkgroupAccessRequestsList = () => {
  const notif = useNotif()
  const [accessRequests, setAccessRequests] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentRequest, setCurrentRequest] = useState<string | null>(null)

  const getAllWorkgroupAccessRequests = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = await accessRequestService.getAll('pending', 'restricted')
      if (result && !result.is_error) {
        setAccessRequests(result.data ?? [])
      }
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }, [setAccessRequests, accessRequestService])

  useEffect(() => {
    getAllWorkgroupAccessRequests()
  }, [])

  const columnDefs = [
    {
      headerName: 'Name',
      field: 'requester_name',
      flex: 2,
      resizable: true
    },
    {
      headerName: 'Workgroup Access Requested',
      field: 'workgroup_name',
      flex: 2,
      resizable: true
    },
    {
      field: 'action',
      headerName: 'Action',
      filter: '',
      sortable: false,
      flex: 2,
      resizable: true,
      cellRenderer: (params: any) => (
        <>
          <Button
            type='submit'
            color='edit'
            onClick={() => {
              handleApprove(params.data._id as string)
            }}
            disabled={currentRequest === params.data._id}
            className='btn-sm mr-1 w-20'
          >
            Approve
          </Button>
          <Button
            type='button'
            color='cancel'
            onClick={() => handleCancel(params.data._id as string)}
            disabled={currentRequest === params.data._id}
            className='btn-sm w-20'
          >
            Reject
          </Button>
        </>
      )
    }
  ]

  const handleApprove = async (id: string) => {
    try {
      setCurrentRequest(id)
      const result = await accessRequestService.manage(id, 'approve')
      if (result) {
        if (result.is_error) {
          toast.error(result.message ?? 'Workgroup Access Update Error')
        } else {
          toast.success(result.data.message)
          setAccessRequests(
            accessRequests.filter((request) => request._id !== id) ?? []
          )
          notif.updateNotifCount()
        }
      }
      setCurrentRequest(null)
    } catch (err) {
      console.log(err)
    }
  }

  const handleCancel = async (id: string) => {
    try {
      setCurrentRequest(id)
      const result = await accessRequestService.manage(id, 'reject')
      if (result) {
        if (result.is_error) {
          toast.error(result.message ?? 'Workgroup Access Update Error')
        } else {
          toast.success(result.data.message)
          setAccessRequests(
            accessRequests.filter((request) => request._id !== id) ?? []
          )
          notif.updateNotifCount()
        }
      }
      setCurrentRequest(null)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className={style.container}>
      {isLoading ? (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      ) : (
        <div className={style.gridContainer}>
          <LoginPortalGrid
            rowData={accessRequests}
            defaulColDef={defaulColDef}
            columnDefs={columnDefs}
          />
        </div>
      )}
    </div>
  )
}
export default WorkgroupAccessRequestsList
