import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Card, Col, Container, Row } from 'reactstrap'
import { toast } from 'react-toastify'
import {
  AuthService,
  AuthPageResponseType,
  PortalType
} from '@digitalworkflow/dwloginclient'

import '../../assets/scss/login.scss'
import bgisLogo from '../../assets/images/bgis-logo.png'
import vector2 from '../../assets/images/vector2.png'
import { apiBaseURL } from '../../constants'
import { LocalSettings } from '../../utils/LocalSettings'

const authService = AuthService.instance()

/**
 * pages/Login/SSORedirect component.
 *
 * @remarks
 * Page where users can redirect to specific portal after SSO success
 *
 * @component SSORedirect
 * @category Page
 */
const Login = () => {
  const [portal, setPortal] = useState<PortalType>({
    buttons: {
      login_with_SSO: false,
      login_with_office365: false,
      login_with_email: true,
      register: true,
      login_with_magic_key: false,
      sso: {}
    },
    login_portal_id: '',
    portal_logo: '',
    portal_logo_full: '',
    portal_name: '',
    portal_theme: '',
    redirection_location_after_success: '/hub/dashboard',
    action_buttons: [],
    client_id: '',
    client_secret: ''
  })
  const { id } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  const redirect_url = searchParams.get('redirect_url')

  const dwTheme = LocalSettings.getTheme()
  console.log(token, redirect_url)

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code')
    if (code === 'NOT_ACTIVE') {
      toast.error(
        'Sorry! you are not allowed to access this page. Request BGIS Workflow Admin to allow you an access.'
      )
    }
    if (id) {
      getPortalDetails(id)
    }
  }, [id])

  useEffect(() => {
    if (portal.portal_name !== '' && token && redirect_url) {
      LocalSettings.setMultiAuthToken(token)
      setTimeout(() => {
        window.location.href = `${redirect_url}?token=${token}`
      }, 1000)
    }
  }, [portal])

  const getPortalDetails = async (id: string) => {
    /** Get Project specific login page information : logo, redirection url, buttons */
    AuthService.setPortalLoginId(id)

    try {
      const res: AuthPageResponseType | undefined =
        await authService.getLoginPage(id)

      console.log('res data', res)
      if (res && res?.data) {
        const portalData = {
          ...portal,
          buttons: res.data?.project?.data?.buttons,
          login_portal_id: res.data?.project?.data?.login_portal_id,
          portal_logo: res.data?.project?.data?.portal_logo,
          portal_logo_full: res.data?.project?.data?.portal_logo_full,
          portal_name: res.data?.project?.data.portal_name,
          redirection_location_after_success:
            res.data?.project?.data?.redirection_location_after_success,
          portal_theme: res.data?.project?.data.portal_theme
        }
        // console.log(res, project)
        LocalSettings.setProjectId(id)
        LocalSettings.setPortalData(JSON.stringify(portalData))
        LocalSettings.setTheme(portalData?.portal_theme ?? '')
        if (id === 'lrLUHbGQ')
          LocalSettings.setLoginPortalData(JSON.stringify(portalData))

        document.body.classList.add(
          'theme-' + portalData?.portal_theme ?? 'bgis'
        )
        setPortal(portalData)
        console.log(portalData)
      } else {
        toast.error(
          'Something has gone wrong, our software developers have been notified. '
        )
        console.log(
          'Something has gone wrong, our software developers have been notified. '
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const projectTheme = useMemo(() => {
    return portal.portal_theme ?? dwTheme
  }, [dwTheme, portal])

  return (
    <div className={`theme-${projectTheme}`}>
      <Helmet>
        {portal.portal_name !== '' && <title>{portal.portal_name}</title>}
      </Helmet>
      <div className='login-page pt-sm-3 '>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5} className='login-section'>
              <Card className='overflow-hidden theme-card'>
                <div className='login-header'>
                  <div className='title'>
                    <img
                      src={
                        portal
                          ? `${apiBaseURL + portal.portal_logo_full}`
                          : bgisLogo
                      }
                      alt=''
                      className='img-fluid '
                    />
                  </div>
                  <img src={vector2} alt='' className='img-fluid vector-img' />
                </div>
                <div className='card-body pb-3 theme-card-body'>
                  <div className='text-darkblue p-2'>
                    <h5 className='text-darkblue text-center project-name'>
                      Redirecting to {portal?.portal_name}...
                    </h5>
                  </div>
                </div>
                {/* <CardBody className='pt-4'>
                  <div> Redirecting... </div>
                </CardBody> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Login
