import React, { useMemo, useRef, useState } from 'react'
import polygon from '../../assets/images/polygon.svg'
import { Tooltip } from 'reactstrap'
import style from './Role.module.scss'
import { useIsOverflow } from '../../hooks/userIsOverflow'

interface IRole {
  title: string
  groups?: number
  totalMembers?: number
  workgroupRoleId: string | undefined
  activeWorkgroupRoleId?: string
  handleActiveRole: (workgroupRoleId: string | undefined) => void
}
const Role = ({
  title,
  groups,
  totalMembers,
  workgroupRoleId,
  activeWorkgroupRoleId,
  handleActiveRole
}: IRole) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  const parentRef = useRef(null)
  const childRef = useRef(null)

  const isOverflow = useIsOverflow(parentRef, childRef)
  const toolTipContent = useMemo(() => {
    if (isOverflow) {
      return (
        <p>
          <b>Name: </b> {title} <br />
          {totalMembers !== undefined && (
            <span>
              <b>Total Members: </b>
              {totalMembers}
            </span>
          )}
        </p>
      )
    } else {
      if (totalMembers !== undefined) {
        return (
          <p>
            <span>
              <b>Total Members: </b>
              {totalMembers}
            </span>
          </p>
        )
      } else {
        return null
      }
    }
  }, [isOverflow, totalMembers, title])
  return (
    <div className='flex'>
      <div
        className={`${style.roleWorkgroup} ${
          activeWorkgroupRoleId === workgroupRoleId ? style.activeRole : ''
        } flex justify-between items-center`}
        onClick={() => handleActiveRole(workgroupRoleId)}
      >
        <div
          ref={parentRef}
          className={style.titleContainer}
          style={{
            width: groups !== undefined ? '55%' : '100%'
          }}
        >
          <span
            className={`${
              activeWorkgroupRoleId === workgroupRoleId ? style.activeTitle : ''
            }`}
            id={'titleTooltip' + workgroupRoleId}
            ref={childRef}
          >
            {title}
          </span>
        </div>
        {toolTipContent && (
          <Tooltip
            isOpen={tooltipOpen}
            target={'titleTooltip' + workgroupRoleId}
            toggle={toggle}
          >
            {toolTipContent}
          </Tooltip>
        )}
        {groups !== undefined && (
          <span className={style.group}>{`${groups} ${
            groups > 1 ? 'Workgroups' : 'Workgroup'
          }`}</span>
        )}
      </div>
      <div className={style.polygon}>
        {activeWorkgroupRoleId === workgroupRoleId && <img src={polygon} />}
      </div>
    </div>
  )
}

export default Role
