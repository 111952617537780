import { apiBaseURL } from '../constants'
import { LocalSettings } from './LocalSettings'

export const exportData = async (urlPath: string, filename: string) => {
  const url = `${apiBaseURL}api/v1/${urlPath}`

  const headers = {
    'X-Auth-Token': LocalSettings.getAuthToken() ?? ''
  }

  fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return response.blob()
    })
    .then((blob) => {
      const link = document.createElement('a')
      const blobUrl = window.URL.createObjectURL(blob)
      link.href = blobUrl

      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(blobUrl)
    })
    .catch((error) => {
      console.error('Error fetching data:', error)
    })
}
