import axios, { AxiosResponse } from 'axios'

const apiBaseUrl = `https://tools.dwf.bgis.com/tools/massupdate/api/workflowdata/hrms/?env=ba04&email=`

/**
 * Fetches RS permissions for a user.
 * @param email The email for which to fetch RS permissions.
 * @returns
 */
export async function fetchRsPermissions(
  email: string
): Promise<AxiosResponse> {
  try {
    // Use a default email, because API returns data of neil.ellingson@bgis.com
    const userEmail = email.toLowerCase()
    const result = await axios.get(`${apiBaseUrl}${userEmail}`)
    return result.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
