import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { toast } from 'react-toastify'
import {
  AuthService,
  AuthPageResponseType,
  PortalType
} from '@digitalworkflow/dwloginclient'
import EmailLogin from '../../components/EmailLogin/EmailLogin'
import '../../assets/scss/login.scss'
import bgisLogo from '../../assets/images/bgis-logo.png'
import vector2 from '../../assets/images/vector2.png'
import { apiBaseURL } from '../../constants'
import MagicLogin from '../../components/MagicLogin/MagicLogin'
import { LocalSettings } from '../../utils/LocalSettings'
const multi_auth_token = LocalSettings.getMultiAuthToken()
const authService = AuthService.instance()

enum CardType {
  Init = 'init',
  Login = 'login',
  Register = 'register',
  Magic_Login = 'magic_logic'
}

/**
 * pages/Login component.
 *
 * @remarks
 * Page where users can log in
 *
 * @component Login
 * @category Page
 */
const Login = () => {
  const [cardType, setCardContent] = useState<CardType>(CardType.Init)
  const [portal, setPortal] = useState<PortalType>({
    buttons: {
      login_with_SSO: false,
      login_with_office365: false,
      login_with_email: true,
      register: true,
      login_with_magic_key: false,
      sso: {}
    },
    login_portal_id: '',
    portal_logo: '',
    portal_logo_full: '',
    portal_name: '',
    portal_theme: '',
    redirection_location_after_success: '/hub/dashboard',
    action_buttons: [],
    client_id: '',
    client_secret: ''
  })
  const { id } = useParams()
  const dwTheme = LocalSettings.getTheme()

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code')
    if (code === 'NOT_ACTIVE') {
      toast.error(
        'Sorry! you are not allowed to access this page. Request BGIS Workflow Admin to allow you an access.'
      )
    }
    if (id) {
      getPortalDetails(id)
    }
  }, [id])
  const getPortalDetails = async (id: string) => {
    /** Get Project specific login page information : logo, redirection url, buttons */
    AuthService.setPortalLoginId(id)

    try {
      const res: AuthPageResponseType | undefined =
        await authService.getLoginPage(id)

      if (res && res?.data) {
        const portalData = {
          ...portal,
          buttons: res.data?.project?.data?.buttons,
          login_portal_id: res.data?.project?.data?.login_portal_id,
          portal_logo: res.data?.project?.data?.portal_logo,
          portal_logo_full: res.data?.project?.data?.portal_logo_full,
          portal_name: res.data?.project?.data.portal_name,
          redirection_location_after_success:
            res.data?.project?.data?.redirection_location_after_success,
          portal_theme: res.data?.project?.data.portal_theme
        }
        // console.log(res, project)
        LocalSettings.setProjectId(id)
        LocalSettings.setPortalData(JSON.stringify(portalData))
        LocalSettings.setTheme(portalData?.portal_theme ?? '')
        if (id === 'lrLUHbGQ')
          LocalSettings.setLoginPortalData(JSON.stringify(portalData))

        // Auto redirect if user already logged in one of his/her enabled portals
        if (multi_auth_token) {
          const auth_res = await authService.authUserFromToken(multi_auth_token)
          if (auth_res && !auth_res.is_error && auth_res?.data) {
            const filtered_portals = auth_res.data.user.portals_enabled.filter(
              (item: any) => item.login_portal_id === id
            )
            if (filtered_portals.length) {
              window.location.href =
                filtered_portals[0].redirection_location_after_success +
                `?token=${auth_res.data.authToken}`
            }
          }
        }

        document.body.classList.add(
          'theme-' + portalData?.portal_theme ?? 'bgis'
        )
        setPortal(portalData)
      } else {
        toast.error(
          'Something has gone wrong, our software developers have been notified. '
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleGoBackToList = () => {
    setCardContent(CardType.Init)
  }

  const handleLoginSSO = (ssoKey: string) => {
    window.location.href = `/api/v1/auth/microsoft-sso/saml?projectid=${id}&ssoKey=${ssoKey}`
  }

  const projectTheme = useMemo(() => {
    return portal.portal_theme ?? dwTheme
  }, [dwTheme, portal])

  return (
    <div className={`theme-${projectTheme}`}>
      <Helmet>
        {portal.portal_name !== '' && <title>{portal.portal_name}</title>}
      </Helmet>
      <div className='login-page pt-sm-3 '>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5} className='login-section'>
              <Card className='overflow-hidden theme-card'>
                <div className='login-header'>
                  <div className='title'>
                    <img
                      src={
                        portal
                          ? `${apiBaseURL + portal.portal_logo_full}`
                          : bgisLogo
                      }
                      alt=''
                      className='img-fluid '
                    />
                  </div>
                  <img src={vector2} alt='' className='img-fluid vector-img' />
                </div>
                <div className='card-body pb-0 theme-card-body'>
                  <div className='text-darkblue p-2'>
                    <h5 className='text-darkblue text-center project-name'>
                      {portal?.portal_name}
                    </h5>
                  </div>
                </div>
                <CardBody className='pt-4'>
                  {cardType === CardType.Init && (
                    <>
                      {portal.buttons?.sso?.bgis && (
                        <div>
                          <button
                            className='btn btn-light-login w-100 waves-effect waves-light theme-button'
                            onClick={() => handleLoginSSO('bgis')}
                          >
                            BGIS Employee Login
                          </button>
                        </div>
                      )}
                      {portal.buttons?.sso?.protovate && (
                        <div className='mt-3'>
                          <button
                            className='btn btn-light-login w-100 waves-effect waves-light theme-button'
                            onClick={() => handleLoginSSO('protovate')}
                          >
                            Protovate Employee Login
                          </button>
                        </div>
                      )}
                      {portal.buttons?.sso?.rps && (
                        <div className='mt-3'>
                          <button
                            className='btn btn-light-login w-100 waves-effect waves-light theme-button'
                            onClick={() => handleLoginSSO('rps')}
                          >
                            Login with SSO
                          </button>
                        </div>
                      )}
                      {/* {portal.buttons?.login_with_office365 && (
                        <div className='mt-3'>
                          <button className='btn btn-light-login w-100 waves-effect waves-light theme-button'>
                            Login with Office 365
                          </button>
                        </div>
                      )} */}
                      {portal.buttons?.login_with_email && (
                        <div className='mt-3'>
                          <button
                            className='btn btn-light-login w-100 waves-effect waves-light theme-button'
                            onClick={() => setCardContent(CardType.Login)}
                          >
                            Login with Email
                          </button>
                        </div>
                      )}
                      {/* {portal.buttons?.register && (
                        <div className='mt-3 d-grid'>
                          <button
                            className='btn btn-login btn-block waves-effect waves-light'
                            onClick={() => setCardContent(CardType.Register)}
                          >
                            Register
                          </button>
                        </div>
                      )} */}
                      {portal.buttons?.login_with_magic_key && (
                        <div className='mt-3 d-grid'>
                          <button
                            className='btn btn-light-login w-100 waves-effect waves-light theme-button'
                            onClick={() => setCardContent(CardType.Magic_Login)}
                          >
                            Authenticate By Key
                          </button>
                        </div>
                      )}
                    </>
                  )}
                  {cardType === CardType.Login && (
                    <EmailLogin
                      portal={portal}
                      handleGoBackToList={handleGoBackToList}
                    ></EmailLogin>
                  )}

                  {cardType === CardType.Magic_Login && (
                    <MagicLogin
                      portal={portal}
                      handleGoBackToList={handleGoBackToList}
                    />
                  )}
                  {/* <div className='mt-4 text-center'>
                    <Link to='forgot-password' className='text-muted'>
                      <i className='fa-solid fa-lock-keyhole'></i> Forgot your
                      password
                    </Link>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Login
