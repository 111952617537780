import { getSentEmailsAPIURL } from '../constants'

export interface ISentEmailGetOptions {
  page?: number
  limit?: number
  filter?: {
    bcc?: string
    bcc_type?: string
    cc?: string
    cc_type?: string
    subject?: string
    subject_type?: string
    target?: string
    target_type?: string
    timestamp?: string
    timestamp_type?: string
  }
  sort?: string
  sortOrder?: string
}

/**
 * Get Sent Emails Data from API
 *
 * @param param0
 * @returns
 */
export const getSentEmails = async ({
  page = 1,
  limit = 50,
  filter = {},
  sort,
  sortOrder
}: ISentEmailGetOptions = {}) => {
  const params = new URLSearchParams({
    page: `${page}`,
    limit: `${limit}`,
    ...filter
  })

  if (sort) params.append('sort', sort)
  if (sortOrder) params.append('sortOrder', sortOrder)

  const url = `${getSentEmailsAPIURL()}logs/sent-emails?${params.toString()}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return response.json()
}

/**
 * Fetches the email body of a sent email based on the provided ID.
 *
 * @param params - An object containing the email ID.
 * @param params._id - The ID of the sent email.
 *
 * @returns A promise that resolves to a JSON response containing the status and either the email body or an error message.
 *
 * @throws Will throw an error if the fetch operation fails.
 */
export const getSentEmailBody = async ({ _id: id }: { _id: string }) => {
  const url = `${getSentEmailsAPIURL()}logs/sent-emails/body/${id}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return response.json()
}
