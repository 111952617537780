import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import {
  AuthService,
  PortalService,
  PortalType,
  RoleService,
  RoleType,
  UserService,
  UserType,
  WorkgroupService,
  WorkgroupType
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import { SelectDropdown } from '@digitalworkflow/dwreactcommon'
import { manageSelectStyle, userSelectStyle } from '../select/manageUser.styles'
import agGridCustomFilter from '../CustomAGgridComponents/agGridCustomFilter'
import { sortArray } from '../../utils/sortArray'

const authService = AuthService.instance()
UserService.setAuthServiceInstance(authService)
PortalService.setAuthServiceInstance(authService)
RoleService.setAuthServiceInstance(authService)
WorkgroupService.setAuthServiceInstance(authService)
const userService = new UserService()
const portalService = new PortalService()
const roleService = new RoleService()
const workgroupService = new WorkgroupService()
interface IUsers {
  handleEditProfile: (profile: UserType, floating: boolean) => void
}

interface IOption {
  value: string
  label: string
}

type UserFilter = {
  roles: string[]
  workgroups: string[]
  portals: string[]
  status: boolean | undefined
  page?: number
  name: string
  email: string
  last_login: string
}
const status: IOption[] = [
  {
    value: 'true',
    label: 'Active'
  },
  {
    value: 'false',
    label: 'Deactivated'
  }
]

const pageSize = 50

const Users = forwardRef(({ handleEditProfile }: IUsers, ref) => {
  const [users, setUsers] = useState<UserType[]>([])
  const [portals, setPortals] = useState<IOption[]>([])
  const [roles, setRoles] = useState<IOption[]>([])
  const [workgroups, setWorkgroups] = useState<IOption[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [addLoading, setAddLoading] = useState<boolean>(false)
  const currentPageRef = useRef<number>(2)
  const gridRef = useRef<any>(null)
  const isFirstRender = useRef(true)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isFilterAdded, setIsFilterAdded] = useState<boolean>(false)
  const [filter, setFilter] = useState<UserFilter>({
    roles: [],
    workgroups: [],
    portals: [],
    status: undefined,
    name: '',
    email: '',
    last_login: ''
  })
  const [portalLoading, setPortalLoading] = useState<boolean>(false)
  const [roleLoading, setRoleLoading] = useState<boolean>(false)
  const [workgroupLoading, setWorkgroupLoading] = useState<boolean>(false)
  const [pageCount, setPageCount] = useState<number>(0)
  const [pageCounter, setPageCounter] = useState<number>(1)

  const computePages = async function () {
    const userCountResp = await userService.getCount()

    if (!userCountResp.is_error && userCountResp.data?.count) {
      const totalPages = Math.ceil(userCountResp.data.count / pageSize)
      setPageCount(totalPages)
    } // user count reponse
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    handleFilter({ _name: name, _email: email }, 'name')
  }, [name, email])

  useEffect(() => {
    computePages()
    getAllPortals()
    getAllWorkgroups()
    getAllRoles()
  }, [])

  useEffect(() => {
    getAllUsers()
  }, [filter])

  useImperativeHandle(ref, () => ({
    getAllUsers
  }))

  const getAllRoles = useCallback(async () => {
    setRoleLoading(true)
    const _result: ApiResultType<RoleType[]> = await roleService.getAllRole(
      'restricted'
    )
    setRoleLoading(false)
    if (!_result.is_error && _result.data) {
      setRoles(
        _result.data.map((item) => ({
          value: item.id ?? '',
          label: item.role_name
        })) ?? []
      )
    }
  }, [setRoles, setRoleLoading])

  const getAllWorkgroups = useCallback(async () => {
    setWorkgroupLoading(true)
    const _result: ApiResultType<WorkgroupType[]> =
      await workgroupService.getAllWorkgroup('restricted')
    setWorkgroupLoading(false)
    if (!_result.is_error && _result.data) {
      setWorkgroups(
        _result.data.map((item) => ({
          value: item.id ?? '',
          label: item.formatted_workgroup_name ?? item.work_group_name
        })) ?? []
      )
    }
  }, [setWorkgroups, setWorkgroupLoading])

  const getAllPortals = useCallback(async () => {
    setPortalLoading(true)
    const _result: ApiResultType<PortalType[]> =
      await portalService.getAllPortals('restricted')
    setPortalLoading(false)
    if (!_result.is_error && _result.data) {
      setPortals(
        _result.data.map((item) => ({
          value: item.id ?? '',
          label: item.portal_name
        })) ?? []
      )
    }
  }, [setPortals, setPortalLoading])

  const getAllUsers = useCallback(async () => {
    let _result: ApiResultType<UserType[]>

    if (isFilterAdded) {
      const { last_login_date } = gridRef.current.api.getFilterModel()

      const formattedLastLogin = last_login_date
        ? formatDate(last_login_date ? last_login_date.dateFrom : null)
        : ''
      const _filter = { ...filter }
      if (formattedLastLogin) {
        _filter.last_login = formattedLastLogin
      }
      gridRef.current!.api.showLoadingOverlay()
      _result = await userService.getAllUser({
        ..._filter
      })
      gridRef.current!.api.hideOverlay()
    } else {
      setLoading(true)
      _result = await userService.getAllUser({
        page: 1,
        limit: pageSize // 50
      })
      setLoading(false)
    }

    if (!_result.is_error) {
      const sortedData = sortArray(
        _result?.data ?? [],
        'last_login_date'
      ).reverse()
      setUsers(sortedData ?? [])
    } else {
      setUsers([])
    }
  }, [setUsers, setLoading, filter])

  const handleScroll = async () => {
    if (addLoading) {
      return
    }
    setAddLoading(true)
    let currentPage = pageCounter

    if (currentPage >= pageCount) {
      gridRef.current!.api.hideOverlay()
      return
    }

    gridRef.current!.api.showLoadingOverlay()

    const _result: ApiResultType<UserType[]> = await userService.getAllUser({
      ...filter,
      page: currentPageRef.current,
      limit: pageSize // 50
    })
    if (!_result.is_error) {
      if (_result.data && _result.data.length > 0) {
        currentPageRef.current += 1
      } else {
        setAddLoading(false) // no more data - hide loader
        return
      }
      const data = _result.data ?? []
      const filteredData = data.filter(
        (newUser: UserType) =>
          !users.some((oldUser: UserType) => oldUser.id === newUser.id)
      )
      const newUsers = [...users, ...filteredData]
      const sortedData = sortArray(newUsers ?? [], 'last_login_date').reverse()
      setUsers(sortedData)
    }
    currentPage = pageCounter + 1
    setPageCounter(currentPage)

    gridRef.current!.api.hideOverlay()
    setAddLoading(false)
  }

  const formatDate = (date: string) => {
    const parsedDate = new Date(date)
    const year = parsedDate.getFullYear()
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0') // Adding 1 because getMonth() returns zero-based month index
    const day = String(parsedDate.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  const onFilterChanged = async (e: any) => {
    const { last_login_date } = e.api.getFilterModel()
    let value: any

    const formattedLastLogin = formatDate(
      last_login_date ? last_login_date.dateFrom : null
    )
    if (last_login_date && formattedLastLogin !== filter.last_login) {
      value = formattedLastLogin
    }

    if (!last_login_date && filter.last_login) {
      value = ''
    }

    const _filter = { ...filter }
    _filter.last_login = value

    gridRef.current!.api.showLoadingOverlay()
    const _result = await userService.getAllUser({
      ..._filter
    })
    gridRef.current.api.setRowData(_result?.data ?? [])
  }

  const handleFilter = useCallback(
    async (value: any, key: keyof UserFilter) => {
      const _filter = { ...filter }
      if (
        key !== 'status' &&
        key !== 'page' &&
        key !== 'name' &&
        key !== 'email' &&
        key !== 'last_login'
      ) {
        if (value.length > 0) {
          _filter[key] = value.map((item: IOption) => item.value)
        } else {
          _filter[key] = []
        }
      } else if (key === 'status') {
        if (value?.value === 'true') {
          _filter[key] = true
        } else if (value?.value === 'false') {
          _filter[key] = false
        } else {
          delete _filter[key]
        }
      } else if (key === 'name' || key === 'email') {
        _filter.name = value._name
        _filter.email = value._email
      } else if (key === 'last_login') {
        _filter[key] = value
      }
      setFilter({ ..._filter })

      // Check if all filter values are empty
      const isFilterEmpty =
        _filter.roles.length === 0 &&
        _filter.workgroups.length === 0 &&
        _filter.portals.length === 0 &&
        _filter.status === undefined &&
        _filter.name === '' &&
        _filter.email === '' &&
        _filter.last_login === ''

      // Set filterAdded to false if all filter values are empty
      setIsFilterAdded(!isFilterEmpty)
    },
    [setFilter, filter, setIsFilterAdded]
  )

  const columnDefs = [
    {
      headerName: 'Name',
      sortable: true,
      filter: 'agTextColumnFilter',
      unSortIcon: true,
      resizable: true,
      floatingFilter: true,
      floatingFilterComponent: agGridCustomFilter,
      floatingFilterComponentParams: {
        initial_value: filter.name,
        setState: (value: string) => {
          setName(value)
        }
      },
      minWidth: 140,
      flex: 2,
      valueGetter: (params: any) =>
        params.data.first_name + ' ' + params.data.last_name,
      comparator: (a: any, b: any) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      minWidth: 140,
      flex: 2,
      floatingFilter: true,
      floatingFilterComponent: agGridCustomFilter,
      floatingFilterComponentParams: {
        initial_value: filter.email,
        setState: (value: string) => {
          setEmail(value)
        }
      },
      comparator: (a: any, b: any) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
    },
    {
      field: 'last_login_date',
      headerName: 'Last Login',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agDateColumnFilter',
      minWidth: 140,
      flex: 2,
      floatingFilter: true,
      valueFormatter: (params: any) => {
        return params.data.last_login_date
          ? moment(params.data.last_login_date).format('LLL')
          : ''
      },
      cellRenderer: (params: any) => {
        return params.data.last_login_date ? (
          moment(params.data.last_login_date).format('LLL')
        ) : (
          <>
            <div>
              <span style={{ color: 'red' }}>Never logged in</span>
            </div>
          </>
        )
      },
      filterParams: {
        maxValidDate: new Date(),
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          if (!cellValue) {
            return -1
          }
          const date = new Date(cellValue)
          const year = Number(date.getFullYear())
          const month = Number(date.getMonth())
          const day = Number(date.getDate())
          const cellDate = new Date(year, month, day)

          if (cellDate < filterLocalDateAtMidnight) {
            return -1
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1
          }

          return 0
        }
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 140,
      resizable: true,
      flex: 1,
      cellRenderer: (params: any) => (
        <div
          className='action-text'
          onClick={() => handleEditProfile(params.data, false)}
        >
          Manage Profile
        </div>
      )
    }
  ]

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ flex: '0 0 10%' }}>
        <div className='filter-grid gap-2 mt-2 ps-2 mb-2'>
          <span>Filter by:</span>
          <div>
            <SelectDropdown
              placeholder='Portal'
              style={{ ...manageSelectStyle, ...userSelectStyle }}
              options={portals}
              isLoading={portalLoading}
              menuPortalTarget={document.body}
              isMulti
              isClearable
              onChange={(value: any) => handleFilter(value, 'portals')}
            />
          </div>
          <div>
            <SelectDropdown
              placeholder='Role'
              style={{ ...manageSelectStyle, ...userSelectStyle }}
              options={roles}
              isMulti
              isClearable
              isLoading={roleLoading}
              menuPortalTarget={document.body}
              onChange={(value: any) => handleFilter(value, 'roles')}
            />
          </div>
          <div>
            <SelectDropdown
              placeholder='Workgroup'
              style={{ ...manageSelectStyle, ...userSelectStyle }}
              options={workgroups}
              isLoading={workgroupLoading}
              isMulti
              isClearable
              menuPortalTarget={document.body}
              onChange={(value: any) => handleFilter(value, 'workgroups')}
            />
          </div>
          <div>
            <SelectDropdown
              placeholder='Status'
              style={{ ...manageSelectStyle, ...userSelectStyle }}
              options={status}
              isClearable
              menuPortalTarget={document.body}
              onChange={(value: any) => handleFilter(value, 'status')}
            />
          </div>
        </div>
      </div>
      <div style={{ flex: '1 1 90%', overflow: 'auto' }} id='userList'>
        {loading ? (
          <div className='flex justify-center'>
            <Spinner />
          </div>
        ) : (
          <LoginPortalGrid
            rowData={users}
            columnDefs={columnDefs}
            pagination={false}
            gridRef={gridRef}
            onFilterChanged={onFilterChanged}
            handleScroll={handleScroll}
            pageSize={pageSize}
          />
        )}
      </div>
    </div>
  )
})
export default Users
