import React, { useCallback, useEffect, useState } from 'react'
import { NodeProps, TreeView } from '@digitalworkflow/dwreactcommon'
import style from './Role.module.scss'
import {
  AuthService,
  RoleService,
  RoleType
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { IDropdown } from './AddWorkgroup'
import OverlayLoader from '../../components/OverlayLoader/overlayLoader'
import { Button } from 'reactstrap'
import { sortArray } from '../../utils/sortArray'
import { toast } from 'react-toastify'

interface IAddRole {
  handleRemoveRole: () => void
  handleNewRole: () => void
}

const AddRole = ({ handleRemoveRole, handleNewRole }: IAddRole) => {
  RoleService.setAuthServiceInstance(AuthService.instance())

  const roleService = new RoleService()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [roles, setRoles] = useState<any[]>([])
  const [dropdownData, setDropdownData] = useState<IDropdown[]>([])
  useEffect(() => {
    getRoles()
  }, [])

  const getRoles = useCallback(async () => {
    setIsLoading(true)
    const roles: ApiResultType<RoleType[]> = await roleService.getAllRole(
      'restricted'
    )
    if (!roles.is_error) {
      const _roleList = transformData(roles?.data ?? [])
      const _roleDropdownData: RoleType[] = sortArray(
        roles.data ?? [],
        'parent_role_id'
      )
      const _dropdownData = _roleDropdownData.map((item) => {
        return {
          label: item.formatted_role_name,
          value: item.id
        }
      })
      setDropdownData([...(_dropdownData as IDropdown[])])
      setRoles([..._roleList])
    }
    setIsLoading(false)
  }, [setIsLoading, setDropdownData])

  const transformData = useCallback((inputData: RoleType[]) => {
    const nodeMap: { [id: string]: any } = {}
    const existingIds = new Set<string>()

    const rootNodes: any[] = []

    // First pass: Create nodes and populate nodeMap
    inputData.forEach((item) => {
      const node: any = {
        id: item.id,
        title: item.role_name,
        description: item.description,
        isExpand: true,
        parentId: item.parent_role_id || null, // Set parentId to null if it's not defined
        children: []
      }

      existingIds.add(node.id)
      nodeMap[node.id] = node

      if (!node.parentId) {
        rootNodes.push(node)
      }
    })

    // Second pass: Assign children to parent nodes
    inputData.forEach((item: any) => {
      const parent = nodeMap[item.parent_role_id]
      const node = nodeMap[item.id]

      if (parent && node) {
        parent.children.push(node)
      }
    })

    // Third pass: Check if parent IDs exist in the data, if not, promote children to root nodes
    Object.values(nodeMap).forEach((node: any) => {
      if (node.parentId && !existingIds.has(node.parentId)) {
        rootNodes.push(node)
        node.parentId = null
      }
    })

    return rootNodes
  }, [])

  const handleAddWorkgroup = useCallback(
    async (role: NodeProps) => {
      setIsLoading(true)
      const _role = {
        role_name: role.title,
        parent_role_id: role.parentId ?? '',
        work_groups: [],
        approver_group_name_id: role.approverId,
        description: role.description
      }

      const _result = await roleService.createRole(_role)
      setIsLoading(false)
      if (!_result.is_error) {
        toast.success('Role is added successfully in this hierarchy')
        handleNewRole()
      } else {
        toast.error(_result.message)
      }

      getRoles()
    },
    [setIsLoading]
  )

  return (
    <div className={style.roleContainer}>
      <div className={`${style.role} flex justify-between items-center`}>
        <span>Add New Role</span>
        <Button
          onClick={handleRemoveRole}
          type='button'
          color='edit'
          className='btn-sm'
        >
          <i className='fa-solid fa-arrow-left me-2' />
          Go back to role list
        </Button>
      </div>
      {isLoading && <OverlayLoader />}

      {roles.length === 0 ? (
        isLoading ? (
          <></>
        ) : (
          <TreeView
            height='93%'
            addButtonText='Add Role'
            titlePlaceholder='Type role name'
            data={[...roles]}
            isShowDropdown
            duplicateErrorMessage='Role name already taken. Please use a different name.'
            dropdownData={dropdownData}
            dropdownPlaceholder='Select approver group name'
            onChange={handleAddWorkgroup}
          />
        )
      ) : (
        <TreeView
          height='93%'
          addButtonText='Add Role'
          titlePlaceholder='Type role name'
          data={[...roles]}
          isShowDropdown
          duplicateErrorMessage='Role name already taken. Please use a different name.'
          dropdownData={dropdownData}
          dropdownPlaceholder='Select approver group name'
          onChange={handleAddWorkgroup}
        />
      )}
    </div>
  )
}
export default AddRole
