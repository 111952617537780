import { searchAPIBaseUrl } from '../constants'

export const getCronJobs = async (
  size: number,
  from: number,
  filter: string = '',
  sort: string = ''
) => {
  const url = `${searchAPIBaseUrl}cron-history/search`

  const data = {
    size: size,
    from: from,
    ...(filter ? { filter } : {}),
    ...(sort ? { sort } : {})
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  return response.json()
}
