/* eslint-disable camelcase */
import React, { useCallback, useMemo, useState } from 'react'
import { Spinner, Button, Row, Col } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  AuthService,
  PortalService,
  PortalType
} from '@digitalworkflow/dwloginclient'

import style from './portal.module.scss'
import FormikInput from '../Formik/FormikInput/formikInput'
import { PortalButtonType } from '../../types/Portal'
import FormikToggleButton from '../Formik/formikToggleButton'
import FormikSelect from '../Formik/formikSelect'
import ImagePicker from '../ImagePicker/ImagePicker'
import { toast } from 'react-toastify'

const supportedFileTypes = ['jpg', 'jpeg', 'png', 'svg', 'bmp', 'gif']

const isNotEmpty = (value: string) => value.trim().length > 0
const isWithinMaxLength = (value: string, maxLength: number) =>
  value.length <= maxLength

const validationSchema = Yup.object().shape({
  portal_name: Yup.string()
    .required('Portal name is required')
    .test(
      'no-only-spaces',
      'Portal name cannot consist of only spaces',
      isNotEmpty
    )
    .test(
      'max-characters',
      'Portal name must be at most 100 characters',
      (value) => isWithinMaxLength(value, 100)
    ),
  buttons: Yup.object()
    .shape({
      login_with_SSO: Yup.boolean(),
      login_with_email: Yup.boolean(),
      login_with_office365: Yup.boolean(),
      register: Yup.boolean(),
      login_with_magic_key: Yup.boolean(),
      sso: Yup.object().shape({
        bgis: Yup.boolean(),
        protovate: Yup.boolean(),
        rps: Yup.boolean()
      })
    })
    .test('', 'At least one option must be selected', (buttons) => {
      const buttonValues = Object.values(buttons)
      return buttonValues.includes(true)
    }),
  portal_theme: Yup.string().required('Portal theme is required'),
  portal_logo: Yup.mixed()
    .required('Portal logo is requirerd')
    .test(
      'file-type',
      'Supported file types are JPG, PNG, SVG, BMP, GIF only.',
      (value: any) => {
        if (!value) {
          return true
        }

        const logo = typeof value === 'string' ? value : value.name

        const fileExtension = logo?.split('.').pop().toLowerCase()

        return supportedFileTypes.includes(fileExtension)
      }
    )
})

interface FormValue {
  portal_name: string
  redirection_location_after_success: string
  portal_theme: string
  buttons: PortalButtonType
  portal_logo: any
}

const initialValues: FormValue = {
  portal_name: '',
  redirection_location_after_success: '',
  portal_theme: '',
  buttons: {
    login_with_SSO: false,
    login_with_email: false,
    login_with_office365: false,
    register: false,
    login_with_magic_key: false,
    sso: {
      bgis: false,
      protovate: false,
      rps: false
    }
  },
  portal_logo: ''
}

const themeDropdown = [
  { value: 'bgis', label: 'BGIS' },
  { value: 'io', label: 'IO' }
]

const authInstance = AuthService.instance()
PortalService.setAuthServiceInstance(authInstance)

const portalService = new PortalService()

interface IAddPortal {
  handleNewPortal?: () => void
  handleRemovePortal?: () => void
  portal?: PortalType | null
  handleUpdatePortal?: (portal: PortalType) => void
  getPortal?: () => void
}
interface IApiDataObject {
  portal_name: string
  portal_theme: string
  buttons: PortalButtonType
  redirection_location_after_success?: string // Making it optional
}
const AddEditPortal = ({
  handleNewPortal,
  handleRemovePortal,
  handleUpdatePortal,
  portal,
  getPortal
}: IAddPortal) => {
  const [resetFormImage, setResetFormImage] = useState<boolean>(false)
  const initValue = useMemo((): FormValue => {
    let _values = initialValues
    if (portal) {
      _values = {
        portal_logo: portal.portal_logo,
        portal_name: portal.portal_name,
        redirection_location_after_success:
          portal.redirection_location_after_success === 'undefined'
            ? ''
            : portal.redirection_location_after_success,
        portal_theme: portal.portal_theme as string,
        buttons: {
          ...portal.buttons,
          login_with_magic_key: portal.buttons?.login_with_magic_key ?? false,
          sso: {
            bgis: portal.buttons.sso?.bgis ?? false,
            protovate: portal.buttons.sso?.protovate ?? false,
            rps: portal.buttons.sso?.rps ?? false
          }
        }
      }
    }
    return _values
  }, [portal])

  const handleAddPortal = useCallback(
    async (values: FormValue) => {
      const data: IApiDataObject = {
        portal_name: values.portal_name.trim(),
        portal_theme: values.portal_theme,
        redirection_location_after_success:
          values.redirection_location_after_success,
        buttons: values.buttons
      }
      try {
        let result: any = {}

        if (portal) {
          result = await portalService.updatePortalById(
            portal.id as string,
            data,
            typeof values.portal_logo !== 'string'
              ? values.portal_logo
              : undefined
          )
        } else {
          result = await portalService.createPortal(data, values.portal_logo)
        }

        if (result.is_error) {
          toast.error(result.message ?? 'Form Submission Error')
        } else {
          handleUpdatePortal && handleUpdatePortal(result.data)
          getPortal && getPortal()
          toast.success(
            `Portal is ${portal ? 'updated' : 'added'} successfully`
          )
          handleNewPortal && handleNewPortal()
        }
      } catch (e) {
        toast.error('Form Submission Error')
      }
    },
    [portal]
  )

  return (
    <>
      <div className={`p-2 ${style.formContainer}`}>
        {!portal && (
          <div className='flex justify-content-end'>
            <Button
              onClick={handleRemovePortal}
              type='button'
              color='edit'
              className='btn-sm'
            >
              <i className='fa-solid fa-arrow-left me-2' />
              Go back to portals list
            </Button>
          </div>
        )}
        <Formik
          enableReinitialize
          initialValues={initValue}
          validationSchema={validationSchema}
          onSubmit={handleAddPortal}
        >
          {({
            resetForm,
            handleSubmit,
            isSubmitting,
            setFieldTouched,
            setFieldValue,
            touched,
            values,
            errors,
            dirty
          }) => {
            const handleCancel = () => {
              resetForm()
              setResetFormImage(true)
            }

            const handleLogo = (files: FileList) => {
              setResetFormImage(false)
              setFieldTouched('portal_logo', true)
              setFieldValue('portal_logo', files[0])
            }

            return (
              <>
                <form onSubmit={handleSubmit}>
                  <FormikInput
                    placeholder='Portal Name'
                    label='Portal Name'
                    name='portal_name'
                  />
                  <FormikInput
                    placeholder='On Success Redirect URL'
                    label='On Success Redirect URL'
                    name='redirection_location_after_success'
                  />
                  <div>
                    <h6 className='form-label mt-2'>Button Configuration:</h6>

                    <FormikToggleButton
                      name='buttons.sso.bgis'
                      label='BGIS Employee Login'
                      className={style.button}
                    />
                    <FormikToggleButton
                      name='buttons.sso.protovate'
                      label='Protovate Employee Login'
                      className={style.button}
                    />
                    <FormikToggleButton
                      name='buttons.sso.rps'
                      label='Login with SSO'
                      className={style.button}
                    />
                    <FormikToggleButton
                      name='buttons.login_with_email'
                      label='Login with Email'
                      className={style.button}
                    />
                    <FormikToggleButton
                      name='buttons.login_with_magic_key'
                      label='Authenticate By Key'
                      className={style.button}
                    />
                    {touched.buttons && errors.buttons && (
                      <p className='is-invalid'>{errors.buttons as string}</p>
                    )}
                  </div>
                  <FormikSelect
                    options={themeDropdown}
                    name='portal_theme'
                    placeholder='Select Theme'
                    label='Select Theme'
                  />

                  <ImagePicker
                    label='Portal Logo:'
                    className='mt-2'
                    onChange={handleLogo}
                    resetFormImage={resetFormImage}
                    image={portal?.portal_logo}
                    value={
                      typeof values.portal_logo === 'string'
                        ? values.portal_logo
                        : values.portal_logo
                        ? values.portal_logo.name
                        : ''
                    }
                  />
                  {touched.portal_logo && errors.portal_logo && (
                    <p className='is-invalid'>{errors.portal_logo as string}</p>
                  )}

                  <Row className='mb-2 mt-3'>
                    <Col className='d-flex gap-2'>
                      <Button
                        type='submit'
                        color='edit'
                        className='btn-sm'
                        disabled={!dirty}
                      >
                        <i className='fal fa-edit me-2' />
                        {portal ? 'Update' : 'Submit'}
                        {isSubmitting && <Spinner className={style.spinner} />}
                      </Button>
                      <Button
                        type='button'
                        color='cancel'
                        className='btn-sm'
                        onClick={handleCancel}
                        disabled={!dirty}
                      >
                        <i className='fa fa-times me-2' />
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default AddEditPortal
